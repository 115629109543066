import React from 'react';
import { graphql, Link } from 'gatsby';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';

export default ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => (
  <>
    <SEO title={frontmatter.title} description={frontmatter.description} />
    <Layout
      heading={frontmatter.heading}
      subheading={frontmatter.subheading}
      button={frontmatter.button}
    >
      <section className="page-section index-section-two bg-primary text-light">
        <Container>
          <h2
            className="text-center pt-5"
            style={{ fontSize: 62, fontWeight: 'bold' }}
          >
            How it works
          </h2>
          <p
            className="text-secondary text-center pb-5"
            style={{ fontWeight: 300 }}
          >
            Download the Catalyst Connector for free and authorize your Jira credentials to access your data in no time.
          </p>
          <Row>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/icon-step-1@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontSize: 24, fontWeight: 'bold' }}>
                  <br />
                  Step 1
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300, textAlign: 'center' }}>
                  Open Tableau
				  <br />
				  (2019.4 and later)
                </p>
              </Row>
            </Col>
            <Col className="p-5 step2" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/icon-step-2@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontSize: 24, fontWeight: 'bold' }}>
                  <br />
                  Step 2
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300, textAlign: 'center' }}>
                  Open the web data connector link in Tableau{' '}
				  <br />
				  <br />
                <input
                  type="button"
                  onClick={() =>
                    navigator.clipboard.writeText('https://app.daexus.io/jira')
                  }
                  value="Copy link"
                />
                </p>
              </Row>
            </Col>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/icon-step-3@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontSize: 24, fontWeight: 'bold' }}>
                  <br />
                  Step 3
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300, textAlign: 'center' }}>
                  Select your Jira data
                </p>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="page-section bg-secondary text-dark">
        <Container>
          <h2
            className="text-center py-5 text-primary"
            style={{ fontSize: 62, fontWeight: 'bold' }}
          >
            What makes our product different
          </h2>
          <Row>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/secure@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Secure
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300 }} className="text-center">
                  Focused on Data Security by using cutting-edge technology that
                  allows data to be imported straight to users’ computers.
                </p>
              </Row>
            </Col>
            <Col className="p-5 step2" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/easy-to-use@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Easy to use
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300 }} className="text-center">
                  Friendly UI for a great user experience, point and click
                  installation, brings your Jira data in Tableau
                  without ever leaving the app.
                </p>
              </Row>
            </Col>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/Accessible@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Accessible
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300 }} className="text-center">
                  Affordable or even free technology as our main goal is
                  improving lives and adding value. By being creative with our
                  solution, rather than throwing money at it, not only are we
                  helping to preserve energy, we’re also eliminating expenses
                  related to servers and APIs costs that would be passed on to
                  our users.
                </p>
              </Row>
            </Col>
            <Col xs={12} className="text-center pb-5">
              <Button variant="outline-primary" as={Link} to="/jira-products/">
                Tell me more
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="page-section integrations-scheme">
        <Container className="mb-5 text-center">
          <img
            src="/img/jira.png"
            style={{ backgroundColor: 'rgba(255,255,255,0.8)' }}
          />
          <br />
          <br />
          <br />
          <Button variant="secondary" href="/products/#pricing">
            Download now&nbsp;&nbsp;
            <img src="/img/download-button-icon@2x.png" />{' '}
          </Button>
        </Container>
      </section>
    </Layout>
  </>
);

export const pageQuery = graphql`
  query IntegrationsJrPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        subheading
        button
        description
      }
    }
  }
`;
